/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubscriptionPlanResponse } from '../models/SubscriptionPlanResponse';
import type { SubscriptionWithPlanResponsePagedListResponse } from '../models/SubscriptionWithPlanResponsePagedListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionsService {

    /**
     * @returns SubscriptionPlanResponse OK
     * @throws ApiError
     */
    public static getBillingSubscriptionsPlans(): CancelablePromise<Array<SubscriptionPlanResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing/subscriptions/plans',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param pageNumber
     * @param pageSize
     * @param sortBy
     * @param sortDescending
     * @returns SubscriptionWithPlanResponsePagedListResponse OK
     * @throws ApiError
     */
    public static getMeSubscriptions(
        pageNumber?: number,
        pageSize?: number,
        sortBy?: string,
        sortDescending?: boolean,
    ): CancelablePromise<SubscriptionWithPlanResponsePagedListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me/subscriptions',
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'SortBy': sortBy,
                'SortDescending': sortDescending,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param pageNumber
     * @param pageSize
     * @param sortBy
     * @param sortDescending
     * @returns SubscriptionWithPlanResponsePagedListResponse OK
     * @throws ApiError
     */
    public static getOrgSubscriptions(
        pageNumber?: number,
        pageSize?: number,
        sortBy?: string,
        sortDescending?: boolean,
    ): CancelablePromise<SubscriptionWithPlanResponsePagedListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/org/subscriptions',
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'SortBy': sortBy,
                'SortDescending': sortDescending,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param subscriptionId
     * @returns void
     * @throws ApiError
     */
    public static postOrgSubscriptionsCancel(
        subscriptionId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/org/subscriptions/{subscriptionId}/cancel',
            path: {
                'subscriptionId': subscriptionId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
